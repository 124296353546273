import React from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';

const CategoryNav = ({ categories, activeCat }) => {
  const handleClick = (category) => {
    navigate(`/news/?category=${category}`, {
      replace: false,
      state: { isFilter: true },
    });
  };

  return (
    <div className="px-gutter sticky top-0 bg-grey-50 z-50">
      <nav className="flex gap-4 font-bold text-small border-b border-grey-100 py-4">
        <button
          onClick={() => handleClick('all')}
          className={cx({
            'text-primary': !activeCat || activeCat === 'all',
          })}
        >
          All articles
        </button>
        {categories.map((item) => (
          <button
            onClick={() => handleClick(item?.slug?.current)}
            className={cx({
              'text-primary': activeCat === item?.slug?.current,
            })}
          >
            {item.title}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default CategoryNav;
