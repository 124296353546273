import React, { useRef, useEffect } from 'react';
import { SplitText } from 'gsap/SplitText';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import cx from 'classnames';

import { SanityImage } from '@components/sanity/sanityImage';

import { loadingDuration } from '@constants/animation-defaults';

gsap.registerPlugin(ScrollTrigger);
const StandardBanner = ({ title, subtitle, image, layout }) => {
  //Refs
  const containerRef = useRef(null);
  const backgroundWrapper = useRef(null);
  const backgroundImage = useRef(null);
  const headlineRef = useRef(null);
  const timelineRef = useRef(
    gsap.timeline({
      paused: true,
    })
  );
  // Animation
  useEffect(() => {
    const lineChildren = new SplitText(headlineRef.current, {
      type: 'lines',
    });
    new SplitText(headlineRef.current, {
      type: 'lines',
      linesClass: 'overflow-hidden pb-3',
    });

    timelineRef.current
      .to(backgroundImage.current, {
        duration: 3,
        scale: 1.1,
      })
      .from(
        lineChildren.lines,
        {
          duration: 1.5,
          yPercent: 100,
          stagger: 0.1,
          ease: 'power4.out',
        },
        0.2
      );

    gsap.to(backgroundImage.current, {
      yPercent: () => 20,
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top',
        end: '+300%',
        scrub: true,
      },
    });
    setTimeout(() => {
      timelineRef.current.play();
    }, loadingDuration * 1001);
  }, []);
  return (
    <div className="text-white flex relative overflow-hidden w-full" ref={containerRef}>
      <div
        className="w-full absolute z-[-1] left-0 top-0 height-full bg-black transform-center overflow-hidden"
        ref={backgroundWrapper}
      >
        <div
          className="will-change-transform object-cover object-center w-full "
          ref={backgroundImage}
        >
          <SanityImage image={image} className="min-h-[1000px] opacity-40 object-cover" />
        </div>
      </div>
      <div
        className={cx(
          'px-gutter min-h-[90vh] flex flex-col w-full justify-end py-16 gap-4 lg:py-[10vw] lg:min-h-[740px]',
          {
            'lg:items-end': layout === 'right',
          }
        )}
      >
        <h1 ref={headlineRef} className="max-w-3xl">
          {subtitle && <h4 className="mb-4">{subtitle}</h4>}
          {title}
        </h1>
      </div>
    </div>
  );
};

export default StandardBanner;
