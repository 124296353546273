import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import { useLocation } from '@reach/router';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import StandardBanner from '@components/pageModules/modules/StandardBanner';
import PostsList from '@components/blog/PostsList';
import CategoryNav from '@components/blog/CategoryNav';

const NewsPortal = ({ data: { page, posts, categories } }) => {
  const [filteredPosts, setFilteredPosts] = useState([]);
  const { seo, banner, shortDescription } = page || {};
  const { search } = useLocation();

  const parsedQCategory = search.split('category=')[1];

  useEffect(() => {
    let results = [...posts.nodes];

    if (parsedQCategory && parsedQCategory !== 'all') {
      results = results.filter((item) => item.category.slug.current === parsedQCategory);
    }

    setFilteredPosts(results);
  }, [parsedQCategory]);

  return (
    <Layout>
      <PageMeta {...seo} />
      <StandardBanner {...banner} />
      <div className="pt-16" />
      <CategoryNav categories={categories?.nodes || []} activeCat={parsedQCategory} />
      <div className="px-gutter pt-16">
        <h3 className="mb-[0.5em]">Recent News</h3>
        <p className="text-lg leading-relaxed max-w-3xl">{shortDescription}</p>
      </div>
      <PostsList posts={filteredPosts} />
    </Layout>
  );
};

export default NewsPortal;

export const pageQuery = graphql`
  query blogPortalQuery {
    page: sanityBlogPortal {
      seo {
        ...SEO
      }
      shortDescription
      banner {
        title
        subtitle
        layout
        image {
          ...Image
        }
        config {
          ...ModuleSettings
        }
      }
    }
    posts: allSanityBlogPost(sort: { fields: postDate, order: DESC }) {
      nodes {
        id
        title
        shortDescription
        postImage {
          ...ImageWithPreview
        }
        postDate: postDate(formatString: "MMMM-DD-YYYY")
        category {
          _id
          title
          slug {
            current
          }
        }
        slug {
          current
        }
      }
    }
    categories: allSanityBlogCategory {
      nodes {
        _id
        title
        slug {
          current
        }
      }
    }
  }
`;
